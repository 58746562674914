import axios from 'axios';

export default {
  name: 'DataRequest',
  axiosInstance: null,
  connected: false,
  getAxios() {
    if (this.axiosInstance === null) {
      this.axiosInstance = axios.create({ baseURL: '/api' });
      // Tentative de reconnexion à la première requête
      this.reconnect();
    }
    return this.axiosInstance;
  },
  get(url, callbackFunc, errorCallbackFunc) {
    this.getAxios()
      .get(url)
      .then((response) => {
        if (callbackFunc !== undefined) {
          callbackFunc(response.data);
        }
      })
      .catch((error) => {
        if (errorCallbackFunc !== undefined) {
          errorCallbackFunc(error.response);
        } else {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      });
  },
  post(url, postData, callbackFunc, errorCallbackFunc) {
    this.getAxios()
        .post(url,
            postData)
        .then((response) => {
          if (callbackFunc !== undefined) {
            callbackFunc(response.data);
          }
        })
        .catch((error) => {
          if (errorCallbackFunc !== undefined) {
            errorCallbackFunc(error.response);
          } else {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        });
  },
  put(url, postData, callbackFunc, errorCallbackFunc) {
    this.getAxios()
        .put(url,
            postData)
        .then((response) => {
          if (callbackFunc !== undefined) {
            callbackFunc(response.data);
          }
        })
        .catch((error) => {
          if (errorCallbackFunc !== undefined) {
            errorCallbackFunc(error.response);
          } else {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        });
  },
  delete(url, callbackFunc, errorCallbackFunc) {
    this.getAxios()
      .delete(url)
      .then((response) => {
        if (callbackFunc !== undefined) {
          callbackFunc(response.data);
        }
      })
      .catch((error) => {
        if (errorCallbackFunc !== undefined) {
          errorCallbackFunc(error.response);
        } else {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      });
  },
  setApiToken(apiToken) {
    this.connected = true;
    // Sauvegarde dans le storage locale
    const creationDate = new Date();
    localStorage.setItem('token', apiToken);
    localStorage.setItem('tokenCreationDate', creationDate.valueOf());
    axios.defaults.headers.common['X-AUTH-TOKEN'] = apiToken;
  },
  /**
     * Try to reconnect if token is always valid
     */
  reconnect() {
    const timestampToHours = 1000 * 60 * 60;
    if (localStorage.getItem('token') !== undefined) {
      const tokenCreationDate = localStorage.getItem('tokenCreationDate');
      if (tokenCreationDate !== undefined) {
        const now = new Date();
        const nowTimestamp = now.valueOf();
        const timeDiff = nowTimestamp - tokenCreationDate;
        // Durée de validité de 10h
        if (timeDiff / timestampToHours < 10) {
          this.getAxios().defaults.headers.common['X-AUTH-TOKEN'] = localStorage.getItem(
            'token',
          );
          this.connected = true;
        }
      }
    }
    return this.isConnected();
  },
  isConnected() {
    return this.connected;
  },
};
