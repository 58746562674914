import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from './pages/Home.vue';
import Application from './pages/Application.vue';
import About from './pages/About.vue';
import Contact from './pages/Contact.vue';
import Policy from './pages/Policy.vue';
import Sitemap from './pages/Sitemap.vue';

Vue.use(VueRouter);

export default new VueRouter({
  mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { title: 'Home' },
    },
    {
      path: '/map',
      name: 'Map',
      component: () => import(/* webpackChunkName: "Map" */'./pages/Map.vue'),
      meta: { title: 'Map' },
    },
    {
      path: '/map/tags/:defaultTags',
      name: 'Map-with-tags',
      component: () => import('./pages/Map.vue'),
      meta: { title: 'Map' },
    },
    {
      path: '/map/place/:defaultPlace',
      name: 'Map-with-place',
      component: () => import('./pages/Map.vue'),
      meta: { title: 'Map' },
    },
    {
      path: '/application',
      name: 'Application',
      component: Application,
      meta: { title: 'Application' },
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      meta: { title: 'About' },
    },
    {
      path: '/contact/:defaultSubject?/:defaultContent?',
      name: 'Contact',
      component: Contact,
      props: true,
      meta: { title: 'Contact' },
    },
    {
      path: '/policy',
      name: 'Policy',
      component: Policy,
      meta: { title: 'Policy' },
    },
    {
      path: '/sitemap',
      name: 'Sitemap',
      component: Sitemap,
      meta: { title: 'Sitemap' },
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "Login" */'./pages/Login.vue'),
      meta: { title: 'Login' },
    },
    {
      path: '/admin',
      name: 'Admin',
      component: () => import(/* webpackChunkName: "Admin" */'./pages/Admin.vue'),
      meta: { title: 'Administration' },
    },
    {
      path: '*',
      name: 'Home-404',
      component: Home,
      meta: { title: 'Home' },
    },
  ],
});
