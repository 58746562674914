<template>
  <v-row id="favorites" dense>
    <v-col cols="6" sm="3" v-for="favorite in favorites" v-bind:key="favorite.id">
      <v-hover v-slot:default="{ hover }">
        <v-card
          class="favorite-card"
          v-bind:elevation="hover ? 12 : 0"
          v-bind:class="{ 'on-hover': hover }"
          dark
          v-bind:color="favorite.color"
          v-on:click="clicked(favorite.id)"
        >
          <v-card-title>{{ favorite.title }}</v-card-title>
          <v-img v-bind:src="favorite.img" class="white--text align-center"></v-img>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import DataRequest from '@/libs/DataRequest';

export default {
  name: 'Favorites',
  data() {
    return {
      favorites: [],
    };
  },
  mounted() {
    if (localStorage.favorites) {
      this.favorites = localStorage.favorites;
    } else {
      DataRequest.get('/tag/favorites', (favorites) => {
        const favoritesPictures = ['camera.jpg', 'homes.jpg', 'coliseum.jpg', 'music.jpg'];
        const favoritesColors = ['#035573', '#153440', '#035573', '#153440'];
        for (let favoriteIndex = 0; favoriteIndex < favorites.length; favoriteIndex++) {
          favorites[favoriteIndex].img = require(`../../../public/${favoritesPictures[favoriteIndex]}`);
          favorites[favoriteIndex].color = favoritesColors[favoriteIndex];
        }
        this.favorites = favorites;
      });
    }
  },
  methods: {
    clicked(tagId) {
      this.$router.push({ name: 'Map-with-tags', params: { defaultTags: tagId } });
    },
  },
};
</script>

<style scoped>
.row#favorites {
  margin-bottom: 0.5rem;
}

@media only screen and (min-width: 800px) {
  .row#favorites {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.v-card > div > div.v-image__image {
  transition: transform 0.2s;
}

.v-card:hover div.v-image__image {
  transform: scale(1.2);
}

.v-card:not(.on-hover) {
  opacity: 0.9;
}
</style>
