import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueAnalytics from 'vue-analytics';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import i18n from './i18n';

Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();

Vue.use(VueMeta);
Vue.use(VueAnalytics, {
  id: 'UA-69690847-1',
  router,
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
