<template>
  <v-app>
    <Menu></Menu>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Menu from './components/Menu.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: { Menu, Footer },
  watch: {
    $route(to) {
      document.title = this.$t(to.meta.title) || "C'est là!";
    },
  },
  mounted() {
    // Hack pour Android qui pose un problème de hauteur
    if (navigator.userAgent.toLowerCase().toLowerCase().indexOf('android') !== -1) {
      document.getElementsByTagName('body')[0].classList.add('android');
    }
  },
};
</script>

<style>
* {
  letter-spacing: 0 !important;
}

.android .v-application--wrap {
  min-height: calc(100vh - 56px);
}
</style>
