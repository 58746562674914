<template>
  <v-container>
    <v-main>
      <h2>{{ $t('Sitemap') }}</h2>
      <h3>{{ $t('Pages') }}</h3>
      <ul>
        <li>
          <router-link to="/">{{ $t('Home') }}</router-link>
        </li>
        <li>
          <router-link to="/map">{{ $t('Map') }}</router-link>
        </li>
        <li>
          <router-link to="/application">{{ $t('Application') }}</router-link>
        </li>
        <li>
          <router-link to="/about">{{ $t('About') }}</router-link>
        </li>
        <li>
          <router-link to="/contact">{{ $t('Contact') }}</router-link>
        </li>
        <li>
          <router-link to="/policy">{{ $t('Policy') }}</router-link>
        </li>
      </ul>
      <h3>{{ $t('Themes') }}</h3>
      <ul>
        <li v-for="tag in tags" v-bind:key="tag.id">
          <router-link v-bind:to="'/map/tags/' + tag.id">{{ tag.title }}</router-link>
        </li>
      </ul>
    </v-main>
  </v-container>
</template>

<script>
import Shared from '../libs/Shared';

export default {
  name: 'Sitemap',
  data() {
    return {
      tags: [],
    };
  },
  mounted() {
    Shared.getSuggestsInSimpleArray(this.$store, (result) => {
      this.tags = result;
    });
  },
};
</script>

<style scoped>
</style>
