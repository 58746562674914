<template>
  <v-container>
    <v-main>
      <h2 class="text-h4 title">{{ $t('Contact') }}</h2>
      <v-alert
        v-if="messageResponse !== ''"
        cols="12"
        v-bind:type="messageResponseType"
        >{{ messageResponse }}</v-alert
      >
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-text-field
            v-model="userRequest.name"
            v-bind:rules="textRules"
            v-bind:label="$t('Name')"
            required
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field
            v-model="userRequest.email"
            v-bind:rules="emailRules"
            v-bind:label="$t('E-mail')"
            required
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field
            v-model="userRequest.subject"
            v-bind:rules="textRules"
            v-bind:label="$t('Subject')"
            required
          ></v-text-field>
        </v-row>
        <v-row>
          <v-textarea
            v-model="userRequest.content"
            v-bind:rules="textRules"
            required
          ></v-textarea>
        </v-row>
        <v-row class="google-recaptcha">
          <VueRecaptcha
            sitekey="6LfUs7QUAAAAAA2Ejze3zj-haDTUqZqRPDLBCWcg"
            v-bind:loadRecaptchaScript="true"
            v-on:verify="checkRecaptcha"
          ></VueRecaptcha>
        </v-row>
        <v-row>
          <v-btn
            v-bind:disabled="!fullForm"
            color="success"
            v-on:click="sendForm"
            >{{ $t('Submit') }}</v-btn
          >
        </v-row>
      </v-form>
    </v-main>
  </v-container>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import DataRequest from '../libs/DataRequest';

export default {
  name: 'Contact',
  components: {
    VueRecaptcha,
  },
  props: {
    defaultContent: {
      type: String,
      default: '',
    },
    defaultSubject: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userRequest: {
        name: '',
        email: '',
        subject: '',
        content: '',
        recaptcha: '',
      },
      valid: false,
      messageResponse: '',
      messageResponseType: '',
      textRules: [
        (v) => !!v || 'Required field',
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    };
  },
  mounted() {
    this.userRequest.content = this.defaultContent;
    this.userRequest.subject = this.defaultSubject;
  },
  computed: {
    fullForm() {
      return this.valid && this.userRequest.recaptcha !== '';
    },
  },
  methods: {
    checkRecaptcha(response) {
      if (response) {
        this.userRequest.recaptcha = response;
      }
    },
    sendForm() {
      DataRequest.post('/userrequest/add', this.userRequest, () => {
        this.messageResponse = this.$t('Thank you, your message has been send.');
        this.messageResponseType = 'success';
      }, (errorResponse) => {
        this.messageResponse = errorResponse.data;
        this.messageResponseType = 'error';
      });
    },
  },
};
</script>

<style scoped>
.google-recaptcha {
  margin-bottom: 1rem;
}
form {
  margin-top: 1rem;
}
</style>
