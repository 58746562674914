<template>
  <v-container>
    <v-main>
      <h2 class="text-h4 title">{{ $t('Application') }}</h2>
      <p class="text-justify">{{ $t('Presentation') }}</p>
      <p class="text-center">
        <a
          v-if="this.$i18n.locale === 'fr'"
          href="https://play.google.com/store/apps/details?id=fr.c_est_la.app&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img
            width="250px"
            alt="Disponible sur Google Play"
            src="https://play.google.com/intl/en_us/badges/images/generic/fr_badge_web_generic.png"
          />
        </a>
        <a
          v-else
          href="https://play.google.com/store/apps/details?id=fr.c_est_la.app&hl=fr&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img
            width="250px"
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
          />
        </a>
      </p>
      <p class="text-center">
        <iframe
          v-if="this.$i18n.locale === 'fr'"
          src="https://www.youtube.com/embed/a__M411yZzA"
          frameborder="0"
          allowfullscreen
        ></iframe>
        <iframe
          v-else
          src="https://www.youtube.com/embed/XEwN_7akXwQ"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </p>
      <h2 class="title">{{ $t('Informations') }}</h2>
      <p>{{ $t('Reviews') }}</p>
      <ul>
        <li>
          <a href="http://www.android-logiciels.fr/cest-la/">Android Logiciels</a>
        </li>
        <li>
          <a href="http://applicationsandroidfrance.com/zoom-sur/cest-la-gratuit/"
            >Application Android France</a
          >
        </li>
      </ul>
    </v-main>
  </v-container>
</template>

<script>
export default {
  name: 'Application',
};
</script>

<style scoped>
iframe {
  min-width: 50%;
  min-height: 20rem;
}
</style>
