<template>
  <v-container fluid pa-0>
    <v-row id="find">
      <Carousel></Carousel>
      <v-card shaped elevation="5">
        <v-autocomplete
          hide-no-data
          item-text="title"
          return-object
          v-bind:isLoading="isLoadingAutocomplete"
          v-bind:items="suggests"
          v-bind:label="$t('Find a theme')"
          v-bind:search-input.sync="search"
          v-model="selectedTag"
        ></v-autocomplete>
        <v-btn v-on:click="goToMap" color="#261401" dark>
          {{ $t('Go to the map') }}&nbsp;
          <v-icon right>mdi-map</v-icon>
        </v-btn>
      </v-card>
    </v-row>
    <Favorites></Favorites>
  </v-container>
</template>

<script>
import DataRequest from '../libs/DataRequest';
import Favorites from '../components/Home/Favorites.vue';
import Carousel from '../components/Home/Carousel.vue';
import Shared from '../libs/Shared';

export default {
  name: 'Home',
  components: {
    Carousel,
    Favorites,
  },
  metaInfo: {
    title: 'C\'est là!',
    meta: [
      {
        name: 'og:description',
        content: 'Trouver des points d&#039;intérêts culturels ou insolites partout dans le monde.',
      },
      {
        name: 'description',
        content: 'Trouver des points d&#039;intérêts culturels ou insolites partout dans le monde.',
      },
      { name: 'og:type', content: 'website' },
      { name: 'og:url', content: 'http://www.c-est-la.fr/map' },
    ],
  },
  data: () => ({
    selectedTag: null,
    isLoadingAutocomplete: false,
    search: '',
    suggests: [],
  }),
  mounted() {
    Shared.getSuggestsInSimpleArray(this.$store, (result) => {
      this.suggests = result;
    });
  },
  watch: {
    search(searchPattern) {
      if (searchPattern !== '' && searchPattern !== null) {
        this.isLoadingAutocomplete = true;
        DataRequest.get(`/tag/search/${searchPattern}`, (tags) => {
          this.$store.commit('addSuggests', tags);
          this.suggests = this.$store.getters.suggestsList;
          this.isLoadingAutocomplete = false;
        });
      }
    },
    selectedTag(value) {
      this.$router.push({ name: 'Map-with-tags', params: { defaultTags: value.id } });
    },
  },
  methods: {
    goToMap() {
      this.$router.push({ name: 'Map' });
    },
  },
};
</script>

<style lang="scss">
#find {
  position: relative;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0.5rem;
}

#find .v-card {
  position: absolute;
  min-width: 20rem;
  max-width: 40rem;
  width: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 0 1rem;
}

#find .v-carousel {
  width: 100%;
}

#find button {
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 1rem;
}
</style>
