<template>
  <v-container>
    <v-main>
      <h2 class="text-h4 title">{{ $t('About') }}</h2>
      <p>
        C'est là! a été créé pour faire découvrir à tous des lieux qui méritent
        une attention en dehors des circuits touristiques habituels.
      </p>
      <p>
        Nous pouvons être amené sans le savoir à passer dans des rues chargées
        d'histoire, de voir des maisons ayant appartenues à des personnes
        illustres ou de boire un café dans un lieu immortalisé par le cinéma.
        Pourquoi ne pas en apprendre plus sur le quartier qui vous entoure ou la
        destination de vos vacances ?
      </p>
      <p>
        Le but n'est pas de référencer des endroits dont l'histoire est connue de
        tous, mais de rechercher des lieux insolites ou des anecdotes dans des
        domaines variés.
      </p>
      <p>
        Si vous voulez partager un point d'intérêt, n'hésitez pas à le proposer à
        l'aide du bouton dédié à cet effet sur la carte. Chaque information à
        propos d'un lieu est vérifiée sur au moins deux sources différentes.
      </p>
      <p>
        Merci aux personnes qui proposent de nouveau lieux, cela permet à tous de
        faire connaitre des anecdotes.
      </p>
    </v-main>
  </v-container>
</template>

<script>
export default {
  name: 'About',
};
</script>

<style scoped>
</style>
