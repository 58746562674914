<template>
  <v-carousel cycle hide-delimiters v-bind:show-arrows="false">
    <v-carousel-item
      v-bind:key="pictureIndex"
      v-bind:src="picture.src"
      v-for="(picture, pictureIndex) in carouselPictures"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'Carousel',
  data() {
    return {
      carouselPictures: [
        {
          src: require('../../../public/home_1.jpg'),
        },
        {
          src: require('../../../public/home_2.jpg'),
        },
        {
          src: require('../../../public/home_3.jpg'),
        }],
    };
  },
};
</script>

<style scoped>
</style>
