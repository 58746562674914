<template>
  <v-app-bar app color="#011C26" dark flat dense elevate-on-scroll>
    <v-img class="mx-2" max-height="40" max-width="40" contain @click="goToHome" src="@/assets/favicon256.png"></v-img>
    <v-toolbar-items class="hidden-xs-only">
      <v-btn text to="/">
        <v-icon left>mdi-home</v-icon>C'est là!
      </v-btn>
      <v-btn text to="/map">
        <v-icon left>mdi-map</v-icon>
        {{ $t('Map') }}
      </v-btn>
      <v-btn text to="/application">
        <v-icon left>mdi-android-debug-bridge</v-icon>
        {{ $t('Application') }}
      </v-btn>
      <v-btn text to="/about">
        <v-icon left>mdi-alpha-i-circle</v-icon>
        {{ $t('About') }}
      </v-btn>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-toolbar-items class="hidden-xs-only">
      <v-btn text href="https://www.facebook.com/cestlafr/" target="_blank">
        <v-icon>mdi-facebook</v-icon>
      </v-btn>
      <v-btn text href="https://twitter.com/c_est_la" target="_blank">
        <v-icon>mdi-twitter</v-icon>
      </v-btn>
    </v-toolbar-items>
    <v-menu bottom left>
      <template v-slot:activator="{ on }">
        <v-btn class="hidden-sm-and-up" dark icon v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('Home') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/map">
          <v-list-item-icon>
            <v-icon>mdi-map</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('Map') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/application">
          <v-list-item-icon>
            <v-icon>mdi-android-debug-bridge</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('Application') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/about">
          <v-list-item-icon>
            <v-icon>mdi-alpha-i-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('About') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/policy">
          <v-list-item-icon>
            <v-icon>mdi-scale-balance</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('Policy') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/contact">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('Contact') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: 'Menu',
  methods: {
    goToHome() {
      if (this.$router.currentRoute.name !== 'Home') {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="scss">
@import 'src/scss/variables';
div[role='menu'] {
  z-index: 99999 !important;
}

.v-toolbar .v-image {
  cursor: pointer;
}
</style>
