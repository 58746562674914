import DataRequest from './DataRequest';

export default {
  boundsToUrl(bounds) {
    const sw = bounds._southWest;
    const ne = bounds._northEast;
    return `${sw.lat}/${ne.lat}/${sw.lng}/${ne.lng}`;
  },
  tagsToUrl(tags) {
    let urlTags = 0;
    if (tags.length > 0) {
      urlTags = tags.map((entry) => entry.id).join(',');
    }
    return urlTags;
  },
  prepareSuggests(store, callbackFunc) {
    if (localStorage.defaultSuggets) {
      store.commit('addSuggests', JSON.parse(localStorage.defaultSuggets));
      callbackFunc();
    } else {
      DataRequest.get('/tag/suggests', (suggests) => {
        store.commit('addSuggests', suggests);
        callbackFunc();
      });
    }
  },
  getSuggestsInSimpleArray(store, callbackFunc) {
    this.prepareSuggests(store, () => {
      const result = [];
      const rawSuggests = store.getters.suggests;
      const suggestsIds = Object.keys(rawSuggests);
      for (const suggestIndex in suggestsIds) {
        result.push(rawSuggests[suggestsIds[suggestIndex]]);
      }
      callbackFunc(JSON.parse(JSON.stringify(result)));
    });
  },
};
