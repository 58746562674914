<template>
    <v-footer
        v-show="showed"
        dense
        class="font-weight-medium hidden-xs-only"
    >
        <v-col
            class="text-center"
        >
            <v-btn text to="/policy">{{ new Date().getFullYear() }} — Sylvain DANGIN - {{ $t('Policy') }}</v-btn>
            <v-btn text to="/sitemap">{{ $t('Sitemap') }}</v-btn>
            <v-btn text to="/contact">{{ $t('Contact') }}</v-btn>
        </v-col>
    </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      showed: true,
    };
  },
  created() {
    // Route name null dans le cas d'un lazy loading
    if (this.$route.name !== null) {
      this.testRoute(this.$route.name);
    }
  },
  watch: {
    $route(to) {
      this.testRoute(to.name);
    },
  },
  methods: {
    testRoute(currentRouteName) {
      this.showed = currentRouteName.indexOf('Map') !== 0;
    },
  },
};
</script>

<style scoped>

</style>
