/* eslint-disable no-param-reassign */
/**
 * Lien entre les composants
 */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    suggests: [],
    tags: [],
    tagDialogShowed: false,
  },
  mutations: {
    addSuggests(state, suggests) {
      for (let suggestIndex = 0; suggestIndex < suggests.length; ++suggestIndex) {
        const suggest = suggests[suggestIndex];
        if (state.suggests[suggest.id] === undefined) {
          state.suggests[suggest.id] = suggest;
        }
      }
      localStorage.defaultSuggets = JSON.stringify(state.suggests.filter((item) => item !== null));
    },
    setTags(state, tags) {
      state.tags = tags;
    },
    addTag(state, tag) {
      if (typeof tag.id === 'string') {
        tag.id = parseInt(tag.id, 10);
      }
      if (state.tags.find((tagItem) => tagItem.id === tag.id) === undefined) {
        state.tags.push(tag);
      }
    },
    removeTag(state, tagId) {
      for (let tagIndex = 0; tagIndex < state.tags.length; ++tagIndex) {
        if (state.tags[tagIndex].id === tagId) {
          state.tags.slice(tagIndex, 1);
          break;
        }
      }
    },
    showTagDialog(state) {
      state.tagDialogShowed = true;
    },
    hideTapDialog(state) {
      state.tagDialogShowed = false;
    },
  },
  getters: {
    suggests: (state) => state.suggests,
    suggestsList: (state) => state.suggests.filter(
      (entry) => entry !== null && entry !== undefined,
    ),
    tagDialogShowed: (state) => state.tagDialogShowed,
    tags: (state) => state.tags,
  },
});
