<template>
  <v-container>
    <v-main>
      <h2 class="text-h4 title">{{ $t('Policy') }}</h2>
      <h3>Editeur du site</h3>
      <ul>
        <li>Sylvain Dangin</li>
        <li>78760 - Jouars Pontchartrain</li>
        <li>
          <router-link to="/contact">Contact</router-link>
        </li>
        <li>Directeur de la publication : Sylvain Dangin</li>
      </ul>
      <h3>Hébergement</h3>
      <p>Ce site est hébergé par la société OVH</p>
      <p>Siège social : 2 rue Kellermann - 59100 Roubaix - France.</p>
      <h3>Limite de responsabilité</h3>
      <p>
        Les informations fournies sur ce site sont vérifiées autant que possible
        mais peuvent être inexactes, incomplètes ou fausses. Si une erreur est
        constatée, merci de la signaler avec le
        <router-link to="/contact">formulaire de contact</router-link>afin
        d'apporter une correction au plus vite.
      </p>
      <h3>Vie privée</h3>
      <p>Aucune donnée personnelle n'est collecté par le site.</p>
      <h3>Droit d'auteur</h3>
      <p>
        L'ensemble du site est soumis au droit d'auteur. Toute reproduction est
        interdite sans l'autorisation de l'auteur.
      </p>
      <h3>Crédits des photos</h3>
      <p>Les photos utilisées sur la page d'accueil proviennent :</p>
      <ul>
        <li>
          du site
          <a href="https://unsplash.com">UnSplash</a> pour les raccourcis
          thématiques de la page d'accueil.
        </li>
        <li>
          de David Niblack accessibles sur le site
          <a href="http://imagebase.davidniblack.com/">Imagebase</a> pour le
          caroussel sur la page d'accueil.
        </li>
      </ul>
    </v-main>
  </v-container>
</template>

<script>
export default {
  name: 'About',
};
</script>

<style scoped>
</style>
